import { combineReducers } from '@reduxjs/toolkit';

import { activeFeatureReducer } from './activeFeature';
import { callPopupReducer } from './callPopup';
import { detailsVisiblePrecisionReducer } from './detailsVisiblePrecision';
import { drawingReducer } from './drawing';
import { experimentsReducer } from './experiments';
import { extendedQueryStringReducer } from './extendedQueryString';
import { featuresReducer } from './features';
import { jsonQueryReducer } from './filters';
import { tagsReducer } from './filters/tags';
import { filtersUrlReducer } from './filtersUrl';
import { fskBannerReducer } from './fskBanner';
import { infrastructureReducer } from './infrastructure';
import { isNewbuildingsListingReducer } from './isNewbuildingsListing';
import { lastActionReducer } from './lastAction';
import { listingUrlReducer } from './listingUrl';
import { mapBoundsReducer } from './mapBounds';
import { mortgageReducer } from './mortgage';
import { newbuildingPromoFeaturesReducer } from './newbuildingPromoFeatures';
import { activeNewbuildingPolygonReducer, newbuildingsPolygonsReducer } from './newbuildingsPolygons';
import { nonGeoQueryStringReducer } from './nonGeoQueryString';
import { noopReducer } from './noopReducer';
import { notificationsReducer } from './notifications';
import { offersCountReducer } from './offersCount';
import { promoInventoryReducer } from './promoInventory';
import { queryStringReducer } from './queryString';
import { regionIdReducer } from './regionId';
import { resultsReducer } from './results';
import { rulerReducer } from './ruler';
import { searchResultsReducer } from './searchResults';
import { seoUrlReducer } from './seoUrl';
import { specialPromosReducer } from './specialPromos';
import { userReducer } from './user';
import { IFiltersState } from '../types/filters';
import { TPromoSearchTypes } from '../types/promoSearchTypes';
import { IApplicationState, TReduxActions } from '../types/redux';

export const reducer = combineReducers<IApplicationState, TReduxActions>({
  activeFeature: activeFeatureReducer,
  activeNewbuildingPolygon: activeNewbuildingPolygonReducer,
  callPopup: callPopupReducer,
  detailsVisiblePrecision: detailsVisiblePrecisionReducer,
  drawing: drawingReducer,
  experiments: experimentsReducer,
  extendedQueryString: extendedQueryStringReducer,
  features: featuresReducer,
  filters: combineReducers<IFiltersState, TReduxActions>({
    jsonQuery: jsonQueryReducer,
    tags: tagsReducer,
    promoSearchTypes: noopReducer<TPromoSearchTypes>(null),
  }),
  filtersUrl: filtersUrlReducer,
  isNewbuildingsListing: isNewbuildingsListingReducer,
  lastAction: lastActionReducer,
  infrastructure: infrastructureReducer,
  listingUrl: listingUrlReducer,
  mapBounds: mapBoundsReducer,
  mortgage: mortgageReducer,
  newbuildingPromoFeatures: newbuildingPromoFeaturesReducer,
  newbuildingsPolygons: newbuildingsPolygonsReducer,
  nonGeoQueryString: nonGeoQueryStringReducer,
  notifications: notificationsReducer,
  offersCount: offersCountReducer,
  promoInventory: promoInventoryReducer,
  queryString: queryStringReducer,
  regionId: regionIdReducer,
  results: resultsReducer,
  ruler: rulerReducer,
  searchResults: searchResultsReducer,
  seoUrl: seoUrlReducer,
  specialPromos: specialPromosReducer,
  fskBanner: fskBannerReducer,
  user: userReducer,
});
