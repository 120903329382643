import { TReduxActions } from '../../actions';
import { ESearchResultsActionType } from '../../actions/searchResults';
import { ISearchResults } from '../../types/searchResults';

const defaultState: ISearchResults = {
  isOpen: false,
  url: '',
};

export function searchResultsReducer(state: ISearchResults = defaultState, action: TReduxActions): ISearchResults {
  switch (action.type) {
    case ESearchResultsActionType.SidebarOpened:
      return {
        ...state,
        isOpen: true,
      };
    case ESearchResultsActionType.SidebarClosed:
      return {
        ...state,
        isOpen: false,
      };
    case ESearchResultsActionType.UrlChanged:
      return {
        ...state,
        url: action.payload,
      };
  }

  return state;
}
