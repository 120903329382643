import { Checkbox } from '@cian/ui-kit/checkbox';
import * as React from 'react';

import { IJsonQueryRangeValue } from 'shared/common/packages/api-models/common/json_query';

import { useDebouncedCallback } from '../../../utils/useDebouncedCallback';
import { Filter } from '../common/Filter';
import { InlineFilter } from '../common/InlineFilter';
import { InlineFilterGroup } from '../common/InlineFilterGroup';
import { RangeInput } from '../common/RangeInput';

interface IFloorProps {
  floor: IJsonQueryRangeValue;
  isFirstFloor: boolean | null;
  notLastFloor: boolean | null;
  onFloorMinChange(gte: number | null): void;
  onFloorMaxChange(lte: number | null): void;
  onIsFirstFloorChange(isFirstFloor: boolean | null): void;
  onNotLastFloorChange(notLastFloor: boolean | null): void;
}

export const Floor: React.FC<IFloorProps> = ({
  floor: { gte: floorMin = null, lte: floorMax = null },
  isFirstFloor,
  notLastFloor,
  onFloorMinChange,
  onFloorMaxChange,
  onIsFirstFloorChange,
  onNotLastFloorChange,
}) => {
  const handleFloorMinChange = useDebouncedCallback((gte: number | null) => {
    onFloorMinChange(gte);
  }, 300);

  const handleFloorMaxChange = useDebouncedCallback((lte: number | null) => {
    onFloorMaxChange(lte);
  }, 300);

  return (
    <Filter label="Этаж">
      <InlineFilterGroup>
        <InlineFilter>
          <RangeInput
            min={1}
            max={200}
            valueMin={floorMin}
            valueMax={floorMax}
            onChangeMin={handleFloorMinChange}
            onChangeMax={handleFloorMaxChange}
          />
        </InlineFilter>
        <InlineFilter>
          <Checkbox
            label="Не первый"
            checked={isFirstFloor === false}
            onChange={event => onIsFirstFloorChange(event.target.checked ? false : null)}
          />
        </InlineFilter>
        <InlineFilter>
          <Checkbox
            label="Не последний"
            checked={!!notLastFloor}
            onChange={event => onNotLastFloorChange(event.target.checked || null)}
          />
        </InlineFilter>
        <InlineFilter>
          <Checkbox
            label="Только последний"
            checked={notLastFloor === false}
            onChange={event => onNotLastFloorChange(event.target.checked ? false : null)}
          />
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
