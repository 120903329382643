import classNames from 'clsx';
import * as React from 'react';

import * as styles from './SearchResults.css';
import { Loader } from '../Loader';

interface ISearchResultsProps {
  url: string;
}

export const SearchResults: React.FC<ISearchResultsProps> = ({ url }) => {
  const [isLoading, setLoading] = React.useState(true);
  const iframe = React.useRef<HTMLIFrameElement>(null);

  React.useEffect(() => {
    if (iframe.current && iframe.current.contentWindow) {
      setLoading(true);

      const normalizedUrl = url || 'about:blank';
      iframe.current.contentWindow.location.replace(normalizedUrl);
    }
  }, [url]);

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <div className={styles['container']}>
      <div className={styles['loader-wrapper']}>
        <Loader />
      </div>
      <iframe
        ref={iframe}
        className={classNames(styles['iframe'], isLoading && styles['loading'])}
        onLoad={handleLoad}
        frameBorder="0"
      />
    </div>
  );
};
