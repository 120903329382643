import * as React from 'react';

import { getTermsValue, NonEmptyArray } from 'shared/common/packages/JsonQuery';

import { useMortgagePromoSearchTypeOptions, useMortgagePromoSearchTypeValues } from './hooks';
import { FilterChips } from '../../../components/FilterChips';
import { Filter } from '../../../components/advancedFilters/common/Filter';
import { getPromoSearchFilterValuesByType } from '../../../utils/getPromoSearchFilterValuesByType';
import { useContext } from '../../../utils/useContext';

export const MortgagePromoSearchTypeContainer: React.FC = () => {
  const { jsonQuery, promoSearchTypes, onChange } = useContext();

  const allValues = getTermsValue('promo_search_types')(jsonQuery);
  const mortgageValues = useMortgagePromoSearchTypeValues(allValues, promoSearchTypes);
  const mortgageOptions = useMortgagePromoSearchTypeOptions(promoSearchTypes);

  const handleChange = React.useCallback(
    (newValues: NonEmptyArray<string> | null) => {
      onChange({
        action: 'setPromoSearchTypes',
        arguments: [
          {
            allValues,
            newValues,
            availableFilterValues: getPromoSearchFilterValuesByType('mortgage', promoSearchTypes),
          },
        ],
      });
    },
    [allValues, promoSearchTypes, onChange],
  );

  if (!promoSearchTypes) {
    return null;
  }

  return (
    <Filter id="advancedFilter_mortgagePromoSearchType" label="Ипотечные программы">
      <div data-name="MortgagePromoSearchTypeChips">
        <FilterChips options={mortgageOptions} value={mortgageValues} onChange={handleChange} selectType="multiple" />
      </div>
    </Filter>
  );
};
