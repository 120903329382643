import * as React from 'react';
import { useSelector } from 'react-redux';

import { SearchResults } from '../../components/SearchResults';
import { selectUrl } from '../../selectors/searchResults';

export const SearchResultsContainer: React.FC = () => {
  const url = useSelector(selectUrl);

  return <SearchResults url={url} />;
};

SearchResultsContainer.displayName = 'SearchResultsContainer';
