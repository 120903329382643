import * as React from 'react';

import { getTermValue } from 'shared/common/packages/JsonQuery';
/**
 * FIXME убрать зависимость фильтров от карты
 */
// eslint-disable-next-line import/no-restricted-paths
import { PIK_PROMO } from 'shared/map-search/constants/pik';

import { OfferType } from '../../../components/advancedFilters/OfferType';
import { TModifier } from '../../../types/modifier';
import { useContext } from '../../../utils/useContext';

export const OfferTypeContainer: React.FC = () => {
  const { jsonQuery, specialPromo, onChange } = useContext();
  const fromDeveloper = getTermValue('from_developer')(jsonQuery);
  const promo = getTermValue('promo')(jsonQuery);
  const isSpecialPromoActive =
    jsonQuery.geo && specialPromo
      ? jsonQuery.geo.value.some(value => value.type === 'builder' && value.id === specialPromo.developerId)
      : null;

  const handleFromDeveloperChange = React.useCallback(
    (fromDeveloper: boolean | null) => {
      onChange({ action: 'setFromDeveloper', arguments: [fromDeveloper] });
    },
    [onChange],
  );

  const handlePromoChange = React.useCallback(
    (promo: boolean | null) => {
      onChange({ action: 'setPromo', arguments: [promo] });
    },
    [onChange],
  );

  const handleSpecialPromoChange = React.useCallback(
    (isSpecialPromoActive: boolean | null) => {
      if (!specialPromo) {
        return;
      }

      if (isSpecialPromoActive) {
        const modifiers: [TModifier, ...TModifier[]] = [
          { action: 'selectGeo', arguments: [{ type: 'builder', id: specialPromo.developerId }] },
        ];

        if (specialPromo.developerId === PIK_PROMO.developerId) {
          modifiers.push({ action: 'setPikPromo', arguments: [true] });
        }

        onChange(...modifiers);
      } else {
        const modifiers: [TModifier, ...TModifier[]] = [
          { action: 'removeGeo', arguments: [{ type: 'builder', id: specialPromo.developerId }] },
        ];

        if (specialPromo.developerId === PIK_PROMO.developerId) {
          modifiers.push({ action: 'setPikPromo', arguments: [null] });
        }

        onChange(...modifiers);
      }
    },
    [onChange, specialPromo],
  );

  return (
    <OfferType
      fromDeveloper={fromDeveloper}
      promo={promo}
      specialPromoLabel={specialPromo ? specialPromo.filterLabel : null}
      specialPromoActive={isSpecialPromoActive}
      onFromDeveloperChange={handleFromDeveloperChange}
      onPromoChange={handlePromoChange}
      onSpecialPromoChange={handleSpecialPromoChange}
    />
  );
};
