import {
  trackAddFavorite,
  trackClickCard,
  trackCreateFolder,
  trackDeleteFavorite,
  trackFolderNameClick,
  trackGoToApplicationForm,
  trackMyHomeTrapClick,
  trackMyHomeTrapShow,
  trackNewbuildingFiltersSelect,
  trackNewbuildingLinkClick,
  trackNewbuildingPromoHover,
  trackNotificationFolder,
  trackSaveToFolder,
} from './analytics';
import { normalizePage } from './utils';
import { TAnalyticsMessagePayload } from '../../types';

export function eventSiteHandler([, eventParams]: TAnalyticsMessagePayload, pageRaw: unknown) {
  const { action, category, label, sc, products } = eventParams;

  const page = normalizePage(pageRaw);

  switch (true) {
    case category === 'favorite' && action === 'add_from_list_mob':
      trackAddFavorite({ label, sc, products, page });
      break;
    case category === 'favorite' && action === 'remove_from_list_mob':
      trackDeleteFavorite({ label, sc, products, page });
      break;
    case category === 'Listing' && action === 'to_card':
      trackClickCard({ sc, products, page });
      break;
    case category === 'Listing' && action === 'to_card_newbuilding':
      trackNewbuildingLinkClick({ sc, products, page });
      break;
    case category === 'Listing' && action === 'show_sopr' && label === 'PromotionsJK':
      trackNewbuildingPromoHover({ label, products, page });
      break;
    case category === 'Listing' && action === 'Click' && String(label).startsWith('select_corpus'):
    case category === 'Listing' && action === 'Click' && ['developer', 'agent'].includes(label):
      trackNewbuildingFiltersSelect({ label, products, page });
      break;
    case category === 'ListingFavorites' && action === 'add_collection':
      trackCreateFolder({ label, sc, products, page });
      break;
    case category === 'ListingFavorites' && action === 'add_to_collection':
      trackSaveToFolder({ label, sc, products, page });
      break;
    case category === 'ListingFavorites' && action === 'click_collection_name':
      trackFolderNameClick({ label, sc, products, page });
      break;
    case category === 'ListingFavorites' && action === 'Click':
      trackNotificationFolder({ label, sc, products, page });
      break;
    case category === 'Map' && label === 'GoToApplicationForm/pin':
      trackGoToApplicationForm({ sc, products, page });
      break;
    case category === 'myhome' && action === 'show_sopr':
      trackMyHomeTrapShow({ products, page });
      break;
    case category === 'myhome' && action === 'click':
      trackMyHomeTrapClick({ products, page });
      break;
  }
}
