/* eslint-disable max-lines */
import { TFilter } from 'shared/common/packages/Filters/shared/types/advancedFilters';
import { FDealType, FOfferType, isAvailable, isAvailableStrict } from 'shared/common/packages/JsonQuery';
import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';

interface IFilterList {
  availability(jsonQuery: IJsonQuery): boolean;

  list: TFilter[];
}

const FILTERS_LIST: IFilterList[] = [
  {
    availability: isAvailableStrict(FDealType.Sale, FOfferType.FlatNew),
    list: [
      'completionYear',
      'undergroundTime',
      'offerType',
      'squareMeterArea',
      'layout',
      // Видео
      // Наличие фото
      // Тип объявления
      'ceilingHeight',
      'floor',
      'floors',
      'mortgagePromoSearchType',
      'discountPromoSearchType',
      'constructionType',
      'finishing',
      'saleType',
      'apartments',
      'publicationDate',
      'containWords',
      'excludeWords',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Flat),
    list: [
      'dealType',
      'undergroundTime',
      'squareMeterArea',
      'layout',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'kitchenStove',
      'repair',
      'floor',
      'floors',
      'mortgagePromoSearchType',
      'discountPromoSearchType',
      'windows',
      'constructionYear',
      'constructionType',
      // Серия дома
      'demolition',
      'elevators',
      // Пандус
      'parking',
      'saleType',
      'apartments',
      'flatShare',
      'publicationDate',
      'containWords',
      'excludeWords',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Room),
    list: [
      'dealType',
      'undergroundTime',
      'squareMeterArea',
      'flatRooms',
      'roomsInDeal',
      'layout',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'kitchenStove',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'demolition',
      'elevators',
      'parking',
      'saleType',
      'publicationDate',
      'containWords',
      'excludeWords',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.FlatShared),
    list: [
      'dealType',
      'undergroundTime',
      'squareMeterArea',
      'flatRooms',
      'layout',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'kitchenStove',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'demolition',
      'elevators',
      'parking',
      'saleType',
      'apartments',
      'publicationDate',
      'containWords',
      'excludeWords',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.House | FOfferType.HousePart | FOfferType.Townhouse),
    list: [
      'fromMKAD',
      'undergroundTime',
      'suburbanArea',
      'constructionType',
      'heatingType',
      'suburbanUtilities',
      'suburbanFacilities',
      'landStatus',
      'floors',
      'bedrooms',
      'suburbanBathroom',
      'constructionYear',
      'publicationDate',
      'dealType',
      'containWords',
      'excludeWords',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Land),
    list: [
      'fromMKAD',
      'undergroundTime',
      'suburbanArea',
      'suburbanUtilities',
      'landStatus',
      'publicationDate',
      'dealType',
      'containWords',
      'excludeWords',
    ],
  },
  {
    availability: isAvailable(FDealType.Sale, FOfferType.Garage),
    list: [
      'dealType',
      'undergroundTime',
      'squareMeterArea',
      'garageType',
      'garageStatus',
      'garageUtilities',
      'parking',
      'publicationDate',
      'containWords',
      'excludeWords',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Flat),
    list: [
      'dealType',
      'rentalPeriod',
      'undergroundTime',
      'facilities',
      'livingConditions',
      'squareMeterArea',
      'layout',
      'apartments',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'kitchenStove',
      'bathType',
      'repair',
      'windows',
      'floor',
      'floors',
      'constructionYear',
      'constructionType',
      'demolition',
      'elevators',
      // Пандус
      'parking',
      'rentTerms',
      'publicationDate',
      'containWords',
      'excludeWords',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Room),
    list: [
      'dealType',
      'rentalPeriod',
      'undergroundTime',
      'facilities',
      'livingConditions',
      'squareMeterArea',
      'flatRooms',
      'roomsInDeal',
      'layout',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'kitchenStove',
      'bathType',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'demolition',
      'elevators',
      // Пандус
      'parking',
      // Состав съемщиков
      'rentTerms',
      'publicationDate',
      'containWords',
      'excludeWords',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Bed),
    list: [
      'dealType',
      'rentalPeriod',
      'undergroundTime',
      'facilities',
      'livingConditions',
      'squareMeterArea',
      'flatRooms',
      'roomsInDeal',
      'layout',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'kitchenStove',
      'bathType',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'elevators',
      // Пандус
      'parking',
      // Состав съемщиков
      'rentTerms',
      'publicationDate',
      'containWords',
      'excludeWords',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.House | FOfferType.HousePart | FOfferType.Townhouse),
    list: [
      'rentalPeriod',
      'fromMKAD',
      'undergroundTime',
      'suburbanArea',
      'facilities',
      'suburbanFacilities',
      'bathType',
      'suburbanBathroom',
      'repair',
      'floors',
      'bedrooms',
      'constructionType',
      'heatingType',
      'constructionYear',
      'rentTerms',
      'livingConditions',
      'publicationDate',
      'dealType',
      'containWords',
      'excludeWords',
    ],
  },
  {
    availability: isAvailable(FDealType.RentLongterm, FOfferType.Garage),
    list: [
      'dealType',
      'undergroundTime',
      'squareMeterArea',
      'garageType',
      'garageStatus',
      'garageUtilities',
      'parking',
      'publicationDate',
      'containWords',
      'excludeWords',
    ],
  },
  {
    availability: isAvailable(FDealType.RentDaily, FOfferType.Flat),
    list: [
      'onlineBooking',
      'dealType',
      'undergroundTime',
      'facilities',
      'livingConditions',
      'squareMeterArea',
      'apartments',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'kitchenStove',
      'bathType',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'elevators',
      // Пандус
      'parking',
      'publicationDate',
      'containWords',
      'excludeWords',
    ],
  },
  {
    availability: isAvailable(FDealType.RentDaily, FOfferType.Room | FOfferType.Bed),
    list: [
      'onlineBooking',
      'dealType',
      'undergroundTime',
      'facilities',
      'livingConditions',
      'squareMeterArea',
      'flatRooms',
      'roomsInDeal',
      'ceilingHeight',
      'urbanBathroom',
      'balcony',
      'kitchenStove',
      'bathType',
      'repair',
      'floor',
      'floors',
      'windows',
      'constructionYear',
      'constructionType',
      'elevators',
      // Пандус
      'parking',
      // Состав съемщиков
      'publicationDate',
      'containWords',
      'excludeWords',
    ],
  },
  {
    availability: isAvailable(FDealType.RentDaily, FOfferType.House),
    list: [
      'onlineBooking',
      'fromMKAD',
      'undergroundTime',
      'suburbanArea',
      'facilities',
      'livingConditions',
      'suburbanFacilities',
      'bathType',
      'suburbanBathroom',
      'repair',
      'floors',
      'bedrooms',
      'constructionType',
      'heatingType',
      'constructionYear',
      'publicationDate',
      'dealType',
      'containWords',
      'excludeWords',
    ],
  },
  {
    availability: isAvailable(FDealType.Any, FOfferType.Any),
    list: ['publicationDate', 'containWords', 'excludeWords'],
  },
];

export function getAvailableFilters(jsonQuery: IJsonQuery): TFilter[] {
  const type = FILTERS_LIST.find(f => f.availability(jsonQuery));

  return (type && type.list) || [];
}
