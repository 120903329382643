import { ITypedReduxAction } from '../../types/redux/actionType';

export enum ESearchResultsActionType {
  SidebarOpened = 'SEARCH_RESULTS/SIDEBAR/OPENED',
  SidebarClosed = 'SEARCH_RESULTS/SIDEBAR/CLOSED',
  UrlChanged = 'SEARCH_RESULTS/URL/CHANGED',
}

export type TSearchResultsSidebarOpenedAction = ITypedReduxAction<ESearchResultsActionType.SidebarOpened>;
export type TSearchResultsSidebarClosedAction = ITypedReduxAction<ESearchResultsActionType.SidebarClosed>;
export type TSearchResultsUrlChangedAction = ITypedReduxAction<ESearchResultsActionType.UrlChanged, string>;

export type TSearchResultsActions =
  | TSearchResultsSidebarOpenedAction
  | TSearchResultsSidebarClosedAction
  | TSearchResultsUrlChangedAction;
