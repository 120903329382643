import * as React from 'react';

import { TPromoSearchTypes } from 'shared/common/packages/Filters/shared/types/promoSearchTypes';
import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';

import { IExperimentalFeatures, TFilter } from '../../types/filters';
import { TModifier } from '../../types/modifier';
import { ISpecialPromo } from '../../types/specialPromo';
import { IContext, context as Context } from '../../utils/context';
import { modifyJsonQuery } from '../../utils/modifyJsonQuery';

export interface IOnChangeParameters {
  appliedModifiers: [TModifier, ...TModifier[]];
  nextJsonQuery: IJsonQuery;
}

interface IProviderProps {
  jsonQuery: IJsonQuery;
  specialPromo?: ISpecialPromo;
  promoSearchTypes: TPromoSearchTypes;
  features: IExperimentalFeatures;
  onChange(parameters: IOnChangeParameters): void;
  onApply(filterKey?: TFilter): void;
  onClear?(): void;
  onApplyClick?(): void;
  onAdvancedFiltersOpen?(): void;
}

export const Provider: React.FC<React.PropsWithChildren<IProviderProps>> = ({
  children,
  jsonQuery,
  specialPromo,
  promoSearchTypes,
  onChange,
  onApply,
  onClear,
  onApplyClick,
  features,
  onAdvancedFiltersOpen,
}) => {
  const handleChange = React.useCallback(
    (...modifiers: [TModifier, ...TModifier[]]) => {
      onChange({
        appliedModifiers: modifiers,
        nextJsonQuery: modifyJsonQuery(jsonQuery, modifiers),
      });
    },
    [jsonQuery, onChange],
  );

  const context = React.useMemo<IContext>(
    () => ({
      jsonQuery,
      specialPromo,
      promoSearchTypes,
      onChange: handleChange,
      onApply,
      onClear,
      onApplyClick,
      onAdvancedFiltersOpen,
      features,
    }),
    [
      handleChange,
      jsonQuery,
      onAdvancedFiltersOpen,
      onApply,
      onApplyClick,
      onClear,
      specialPromo,
      promoSearchTypes,
      features,
    ],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};
