import { useMemo } from 'react';

import { TPromoSearchTypes } from 'shared/common/packages/Filters/shared/types/promoSearchTypes';
import { getPromoSearchFilterValuesByType } from 'shared/common/packages/Filters/shared/utils/getPromoSearchFilterValuesByType';
import { NonEmptyArray, nonEmptyArrayOr } from 'shared/common/packages/JsonQuery';

export const useMortgagePromoSearchTypeValues = (
  allValues: NonEmptyArray<string> | null,
  promoSearchTypes: TPromoSearchTypes,
) => {
  return useMemo(() => {
    if (!allValues || !promoSearchTypes) {
      return null;
    }

    const availableFilterValues = getPromoSearchFilterValuesByType('mortgage', promoSearchTypes);
    const filteredValues = allValues.filter(value => availableFilterValues.includes(value));

    return nonEmptyArrayOr(filteredValues, null);
  }, [allValues, promoSearchTypes]);
};
