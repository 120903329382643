import { useMemo } from 'react';

import { TPromoSearchTypes } from 'shared/common/packages/Filters/shared/types/promoSearchTypes';

export const useMortgagePromoSearchTypeOptions = (promoSearchTypes: TPromoSearchTypes) => {
  return useMemo(() => {
    if (!promoSearchTypes) {
      return [];
    }

    return [
      { label: 'Неважно', value: null },
      ...promoSearchTypes.mortgage.map(({ value, name: label }) => ({
        value,
        label,
      })),
    ];
  }, [promoSearchTypes]);
};
